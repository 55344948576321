@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overscroll-behavior: none;
  scroll-behavior: smooth;
  width: 100%;
  /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 46.28%, #000000 79.38%), #151C36; */
  background-color: #030303;
  color: #FFFFFF;
  overflow-x: hidden;
  font-family: "articulatcf", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.bg-image {
  background-image: url('./assets/bg.png');
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-size: 100% 100%;
}

code {
  font-family: 'articulatcf',
    sans-serif;
}

.border-app-primary {
  border-color: #939395;
}

.text-app-gray {
  color: rgba(255, 255, 255, 0.4);
}

.text-app-primary {
  color: #AAAAAA;
}

.bg-app-primary {
  background-color: #939395;
}

.bg-app-content {
  background-color: rgba(10, 10, 10, 0.95);
}

.box-border {
  border: 1px solid rgb(49, 49, 49);
}

.bg-app-box {
  background-color: #141619;
}

.bg-blurry {
  background: radial-gradient(43.97% 43.97% at 50% 51.18%,
      rgba(134, 126, 232, 0.0001) 0%,
      rgba(134, 126, 232, 0.264719) 35.94%,
      rgba(134, 126, 232, 0.0001) 100%);
}

.fit-full-h {
  display: flex;
  flex-flow: column;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #141619;
}

::-webkit-scrollbar-thumb {
  /* box-shadow: inset 0 0 10px #131723; */
  background-color: #2B88FA;
  border-radius: 2px;
}

@font-face {
  font-family: "articulatcf";
  src: url("./assets/fonts/ArticulatCF-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "articulatcf";
  src: url("./assets/fonts/ArticulatCF-BoldOblique.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "articulatcf";
  src: url("./assets/fonts/ArticulatCF-DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "articulatcf";
  src: url("./assets/fonts/ArticulatCF-DemiBoldOblique.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "articulatcf";
  src: url("./assets/fonts/ArticulatCF-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "articulatcf";
  src: url("./assets/fonts/ArticulatCF-MediumOblique.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "articulatcf";
  src: url("./assets/fonts/ArticulatCF-Normal.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "articulatcf";
  src: url("./assets/fonts/ArticulatCF-NormalOblique.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "articulatcf";
  src: url("./assets/fonts/ArticulatCF-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "articulatcf";
  src: url("./assets/fonts/ArticulatCF-LightOblique.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "articulatcf";
  src: url("./assets/fonts/ArticulatCF-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: "articulatcf";
  src: url("./assets/fonts/ArticulatCF-ExtraLightOblique.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
} */

@font-face {
  font-family: "articulatcf";
  src: url("./assets/fonts/ArticulatCF-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "articulatcf";
  src: url("./assets/fonts/ArticulatCF-ThinOblique.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

.modal-fadeIn {
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.6s;
  animation-name: fadeIn;
  animation-duration: 0.6s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes fadeIn {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@keyframes fadeOut {
  from {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

blockquote {
  border-left: 4px solid #CCC;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 16px;
}

ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 26px;
}